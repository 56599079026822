:local(.input){
    margin-top:var(--spacing);
    display: grid;
    grid-template-columns: 1fr;

    gap:var(--spacing_half);
}
:local(.logTable){
    margin-top:var(--spacing);
}

:local(.msg){
    padding:var(--spacing);
    text-align: center;
}
:local(.msg.ok){
    color:#093;
}
:local(.msg.fail){
    color:#c30;
}
