:local(.devices) {
    font-size: 12px;
    padding: 0;

    :local(.device) {
        &:hover {
            background: #f5f5f5;
        }

        padding: var(--spacing_half);
        gap: var(--spacing_half);
        align-items: center;
        border-bottom: 1px solid var(--gray6);

        &:last-child {
            border-bottom: 0;
        }

        display: grid;
        grid-template-columns: 1fr 100px;
        border-bottom: 1px solid #ccc;

        @media(max-width:640px) {
            grid-template-columns: 1fr;

        }

        :local(.lsep) {
            margin-top: var(--spacing_quarter);
        }
    }
}