:local(.cnt) {
    display: grid;
    grid-template-rows: auto 1fr;
    height: auto;
    padding-bottom: 50px;
    position: relative;
}

:local(.bigmap) {
    position: absolute;
    top: 32px;
    bottom: 0;
    left: 0;
    right: 0;
}

:local(.devices) {
    max-height: 420px;
    overflow: hidden;
}

:local(.step) {
    display: grid;
    gap: var(--spacing_half);
    grid-template-columns: auto 1fr;

    :local(.number) {
        border-radius: 100px;
        background: var(--mainColor);
        color: #fff;
        height: 30px;
        width: 30px;
        line-height: 30px;
        text-align: center;
    }

    border-bottom: 1px solid var(--gray6);
    padding: var(--spacing);
}

:local(.field) {
    border-bottom: 1px solid var(--gray6);
    padding: var(--spacing);
}

:local(.submit) {
    padding: var(--spacing);
}

:local(.map) {
    margin-top: var(--spacing_half);
}

:local(.title) {
    :local(.steps) {
        font-size: var(--small);
        padding: 5px;

        div {
            display: inline-block;
            margin-right: 2px;
        }

        :local(.stepInfo) {
            display: inline-block;
            margin-right: 2px;

            &:after {
                content: " »";
            }

            &:last-child:after {
                content: ""
            }
        }
    }
}