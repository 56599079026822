:local(.main) {
    margin: 0;
    padding: 0;
    display: grid;
    overflow: auto;
    grid-template-rows: 40px 1fr;
    position: relative;

}

:local(.tools) {
    padding: var(--spacing_half);
    display: grid;
    gap: var(--spacing_half);
    grid-template-columns: 1fr 50px;

    :local(.filters) {
        display: grid;
        grid-template-columns: auto auto 1fr;
        gap: var(--spacing_half);
    }

    :local(.item) {}

    :local(.hoursFilter) {}
}

:local(.maps) {
    padding: var(--spacing_half);
    display: grid;
    overflow: hidden;
    gap: var(--spacing_half);
    height: calc(100vh - 80px);
    grid-template-columns: 3fr 2fr;
    grid-template-areas: "map data";
}

:local(.map) {
    display: grid;
    grid-template-rows: auto 1fr;

    :local(.mapTitle) {
        padding: var(--spacing_quarter);

        :local(.toolsSec) {
            float: right;
        }

    }

    :local(.mapContent) {}
}

:local(.legend) {
    padding: var(--spacing_half);
    margin-top: var(--spacing_half);

    :local(.byUsage) {
        margin-bottom: var(--spacing);

        :local(.item) {
            padding: var(--spacing_half);
            border-bottom: 1px solid #ccc;
            display: grid;
            grid-template-columns: 1fr 100px 100px;
            gap: var(--spacing);
            cursor: pointer;

            &:last-child {
                border: 0;
            }

            :local(.qty) {
                text-align: right;
            }

            &:local(.selected) {
                font-weight: bold;
            }

            :local(.filter) {
                text-align: right;
                text-decoration: underline;
                font-size: var(--small);


            }
        }
    }
}


:local(.map) {
    position: relative;
}