:local(.main) {
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-rows: 100px 1fr;

    @media (max-width:640px) {
        grid-template-rows: 190px 1fr;
    }

    &:local(.withSecondaryBar) {
        grid-template-rows: 100px 1fr 400px;

        @media (max-width:640px) {
            grid-template-rows: 190px 1fr 400px;
        }

    }
}

:local(.inner) {
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;

    &:local(.withSecondaryBar) {
        grid-template-columns: 8fr 6fr;

        @media (max-width:640px) {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 300px;
        }

    }
}

:local(.filters) {

    height: 100px;
    padding: var(--spacing_half);
    display: none;
    background: #fff;

    &:local(.visible) {
        height: auto;
        display: block;
        position: absolute;
        padding: var(--spacing);
        padding-top: 0;
        right: var(--spacing_quarter);
        left: var(--spacing_quarter);
        bottom: 0px;
        z-index: 500;
        box-shadow: 0px -2px 2px -1px rgba(0, 0, 0, .2);
        border-radius: var(--spacing_quarter) var(--spacing_quarter) 0 0;

        @media (min-width:640px) {
            bottom: 40px;
        }
    }

    :local(.title) {
        border-bottom: 1px solid #eee;
        padding-left: var(--spacing_half);
        padding-top: var(--spacing_half);
        padding-bottom: var(--spacing_half);
        margin-bottom: var(--spacing_half);
        margin-left: -24px;
        margin-right: -24px;

    }
}

:local(.closeBtn) {
    cursor: pointer;
    float: right;
    padding: var(--spacing_half);
    margin-top: -8px;
    margin-right: 5px;
}

:local(.statusQr) {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: var(--spacing_half);

    @media (max-width:640px) {
        grid-template-columns: 1fr;

    }
}

:local(.extraFilters1) {
    display: grid;
    gap: var(--spacing_half);
    grid-template-columns: 1fr 1fr;

    @media (max-width:640px) {
        grid-template-columns: 1fr;

    }
}

:local(.batteryFilter),
:local(.lastReportFilter) {
    display: grid;
    line-height: 30px;
    margin-top: var(--spacing_half);
    gap: var(--spacing_half);
    grid-template-columns: auto 1fr auto;
}

:local(.map) {
    position: relative;

    :local(.results) {
        font-size: var(--normal);
        padding: var(--spacing_half);
        min-height: 90px;

        @media (max-width:640px) {
            min-height: 52px;
        }

        :local(.line) {
            display: grid;
            grid-template-columns: 1fr auto;
        }

        :local(.byStatus) {
            padding: 0;

            @media (max-width:640px) {
                display: none;
            }
        }
    }
}

:local(.mapSidebar) {
    overflow-y: scroll;
    border-left: 1px solid var(--gray6);
    padding: 0;

    @media (max-width:640px) {
        border-top: 1px solid var(--gray6);
    }

    :local(.title) {
        padding: var(--spacing);
    }
}

:local(.selectedDevice) {
    padding: var(--spacing);
}

:local(.actions) {
    border-top: 1px solid #eee;
    padding: var(--spacing_half);
}

:local(.bottomTools) {
    z-index: 100;
    position: absolute;
    bottom: 10px;
    left: 10px;

    button {
        margin-right: var(--spacing_half);
    }
}



:local(.message) {
    position: absolute;
    z-index: 10;
    bottom: 10px;
    left: 10px;
    right: 10px;
    border-radius: 20px;
    text-align: center;
    font-size: var(--normal);
    padding: var(--spacing_quarter);

    &:local(.warning) {
        background: #ffcc00cc;
    }

    &:local(.success) {
        background: #009900cc;
        color: #fff;
    }

    &:local(.error) {
        background: #eeb9a8cc;
        color: #cc3300;
    }
}

.taskArea {
    height: 100%;
}