:local(.cnt) {
    border: 1px solid #eee;
    display: grid;
    grid-template-rows: auto 1fr;
}

:local(.bar) {
    background: #f5f5f5;
    padding: var(--spacing_half);
    display: grid;
    grid-template-columns: 1fr auto;
}

:local(.body) {
    overflow: auto;
    height: 100%;
}

:local(.title) {
    font-size: var(--normal);
}