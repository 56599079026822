:local(.cntEditable) {
    grid-template-rows: 55px auto;

    @media (max-width:640px) {
        height: calc(100vh - 90px);
    }
}

:local(.cnt) {
    display: grid;
    grid-template-rows: auto;
    height: 100%;
    gap: 0;
    overflow: hidden;
    position: relative;
    padding: 20px;


}

:local(.input) {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: var(--spacing_half);
    padding: var(--spacing_quarter);
    font-size: var(--big4x);

    input {
        font-size: var(--big2x);
        border: 0;
        outline: none;

        &:hover {
            border: 0;
        }

        &:focus {
            box-shadow: none;
        }
    }

    :local(.inputInner) {
        position: relative;
    }

    :local(.scan) {
        position: absolute;
        top: -5px;
        right: 3px;
        font-size: 36px;
    }

    :global(.ant-btn) {
        height: 45px;
    }

}

:local(.scanCamera) {
    max-width: 100%;
    background: #eee;
    position: relative;

    &:local(.visible) {
        border-bottom: 1px solid #ccc;
    }

    :local(.camera) {
        width: 250px;
        margin: auto;
        overflow: hidden;
        border-radius: 10px;
        padding: 10px;
    }

    :local(.closeBtn) {
        position: absolute;
        z-index: 100;
        font-size: 30px;
        background: #fff;
        border-radius: 50px;
        height: 40px;
        line-height: 40px;
        width: 40px;
        text-align: center;
        right: 10px;
        top: 10px;
    }

    :local(.torchButton) {
        position: absolute;
        z-index: 100;
        font-size: 30px;
        background: #fff;
        border-radius: 50px;
        height: 40px;
        line-height: 40px;
        width: 40px;
        text-align: center;
        right: 10px;
        bottom: 10px;
    }

    :local(.torchMessage) {
        position: absolute;
        z-index: 99;
        bottom: 0px;
        padding-left: 4px;
        padding-top: 4px;
        padding-bottom: 4px;
        right: 0px;
        line-height: 100%;
        padding-right: 60px;
        left: 0px;
        text-align: right;
        background: #ffffffaa;
    }

}

:local(.results) {
    width: 100%;
    overflow: hidden;
    display: grid;
    grid-template-rows: auto 1fr;

    :local(.resultsTitle) {
        :local(.line) {
            padding: var(--spacing_half);
            font-size: var(--normal);
            font-weight: 700;
            border-bottom: 1px solid #eee;
        }

        :local(.byStatus) {
            padding: var(--spacing_half);
        }
    }

    :local(.resultsContent) {
        overflow-y: auto;
        width: 100%;
    }

    :local(.deviceItem) {
        border-bottom: 1px solid #eee;
        padding: var(--spacing_half);
        display: grid;
        align-items: center;
        font-size: var(--small);
        width: 100%;
        grid-template-columns: 1fr 1fr auto;

        :local(.qr) {
            font-weight: 600;
        }

        :local(.change_status) {
            margin-top: 10px;
        }

        :local(.fleet) {
            font-weight: 400;
        }

        :local(.status) {
            background: #00000011;
            border-radius: 50px;
            display: inline-block;
            padding: var(--spacing_quarter) var(--spacing_half);
            font-size: 10px;
            font-weight: bold;
        }

        &:local(.loading) {
            border-left: 3px solid #fc0;
            background: #ffffdd;
        }

        &:local(.notFound) {
            background: #ffeeee;
            border-left: 3px solid #c30;
            color: #c30;
        }

        &:local(.status_live) {
            border-left: 3px solid #3c9;
            background: #f5fff5;
        }

        &:local(.status_in_warehouse) {
            border-left: 3px solid #dda732;
            background: #eee7d9;
        }

        &:local(.status_in_transport_to_deploy) {
            border-left: 3px solid #06c;
            background: #d5e8fa;
        }

        &:local(.status_stolen_suspect) {
            border-left: 3px solid #cc0077;
            background: #ecdde6;
        }

        &:local(.status_in_transport_to_warehouse) {
            border-left: 3px solid #cc7700;
            background: #fae8d4;
        }

        &:local(.status_damaged) {
            border-left: 3px solid #861717;
            background: #fdb4b4;
        }

        &:local(.status_maintenance) {
            border-left: 3px solid #9e5f23;
            background: #e9bc91;
        }

        :local(.deleteItemBtn) {
            cursor: pointer;
            margin-left: var(--spacing);
            width: 30px;
            height: 30px;
            line-height: 25px;
            text-align: center;
            border: 1px solid #eee;
            background: #fff;
            font-size: 30px;
            border-radius: 5px;
            color: #c30;
        }
    }
}

:local(.message) {
    position: absolute;
    z-index: 10;
    bottom: 60px;
    left: 10px;
    right: 10px;
    border-radius: 20px;
    text-align: center;
    font-size: var(--normal);
    padding: var(--spacing_quarter);

    &:local(.warning) {
        background: #ffcc0033;
    }

    &:local(.success) {
        background: #00990033;
    }
}

:local(.setLocation) {
    @media (max-width: 640px) {
        & {
            padding: var(--spacing_half) var(--spacing_half) 0;
        }
    }

    margin-bottom: var(--spacing_half);
}

:local(.actions) {
    background: #f0faf0;
    text-align: center;
    padding: var(--spacing_half);

    :local(.buttons) {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 10px;
    }

    @media (max-width: 640px) {
        & {
            padding: 0;
            overflow-x: hidden;
            overflow-y: hidden;
            white-space: nowrap;
            width: 100%;
        }

        :local(.buttons) {
            display: block;
            margin-top: -12px;
            overflow-x: auto;
            overflow-y: hidden;
            white-space: nowrap;
            width: 100%;

            button {
                display: inline-block;
                margin: var(--spacing_half);
            }
        }
    }
}