:local(.main) {
  margin: 0;
  padding: 0;
  height: 100%;
  display: grid;
  grid-template-rows: 80px 1fr;
}
:local(.query) {
  padding: var(--spacing_half);
  display: grid;
  grid-template-columns: 1fr auto;
  gap: var(--spacing_half);
  :local(.input_area) {
    height: 100%;
  }
}
:local(.response) {
  height: 100%;
  overflow: hidden;
}
