:local(.info) {
    grid-area: info;

    table {
        width: 100%;
        border-collapse: collapse;

        td {
            padding: var(--spacing_half);
            border-bottom: 1px solid var(--gray6);
        }
    }
}

:local(.main) {
    display: grid;
    gap: var(--spacing);
    grid-template-columns: 1fr;
    grid-template-areas: "info";

    @media(max-width:640px) {
        & {
            grid-template-columns: 1fr;
            grid-template-areas: "info"
        }
    }

    :local(.info) {
        grid-area: info;
    }
}


:local(.tableSmall) {
    width: 100%;
    font-size: var(--small);
}

:local(.userPhoto) {
    height: 80px;
    width: 80px;
    background-position: center center;
    background-size: cover;
    border-radius: var(--spacing_quarter);
}