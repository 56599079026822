:local(.input) {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: var(--spacing_half);
    padding: var(--spacing_quarter);
    box-shadow: 0px 0px 8px rgba(0, 0, 0, .2);
    font-size: var(--big4x);

    input {
        font-size: var(--big2x);
        border: 0;
        outline: none;

        &:hover {
            border: 0;
        }

        &:focus {
            box-shadow: none;
        }
    }

    :local(.inputInner) {
        position: relative;
    }

    :local(.scan) {
        position: absolute;
        top: -5px;
        right: 3px;
        font-size: 36px;
    }

    :global(.ant-btn) {
        height: 45px;
    }

}

:local(.scanCamera) {
    max-width: 100%;
    background: #eee;
    position: relative;

    &:local(.visible) {
        border-bottom: 1px solid #ccc;
    }

    :local(.camera) {
        width: 250px;
        margin: auto;
        overflow: hidden;
        border-radius: 10px;
        padding: 10px;
    }

    :local(.closeBtn) {
        position: absolute;
        z-index: 100;
        font-size: 30px;
        background: #fff;
        border-radius: 50px;
        height: 40px;
        line-height: 40px;
        width: 40px;
        text-align: center;
        right: 10px;
        top: 10px;
    }

    :local(.torchButton) {
        position: absolute;
        z-index: 100;
        font-size: 30px;
        background: #fff;
        border-radius: 50px;
        height: 40px;
        line-height: 40px;
        width: 40px;
        text-align: center;
        right: 10px;
        bottom: 10px;
    }

    :local(.torchMessage) {
        position: absolute;
        z-index: 99;
        bottom: 0px;
        padding-left: 4px;
        padding-top: 4px;
        padding-bottom: 4px;
        right: 0px;
        line-height: 100%;
        padding-right: 60px;
        left: 0px;
        text-align: right;
        background: #ffffffaa;
    }

}