:local(.hoursTimeline) {
    margin: var(--spacing2x) 0;

    :local(.line) {
        display: grid;

    }

    :local(.titleLine) {
        font-size: var(--small);
        width: 90px;
        text-align: left;
        padding: var(--spacing_half);
        padding-right: var(--spacing);
        font-weight: bold;
        margin-right: -1px;

        @media(max-width:640px) {
            & {
                padding: 0;
            }
        }
    }

    :local(.hour) {
        font-size: var(--small);
        text-align: center;
        margin-right: -1px;

    }

    :local(.item) {
        background: #f5f5f5;
        border: 1px solid #eee;
        padding: var(--spacing_half);
        font-size: var(--small);
        text-align: center;
        margin-right: -1px;

        @media(max-width:640px) {
            & {
                padding: 0;
            }
        }

        &:local(.hasValue) {
            background: #ffeeee;
            color: #c30;
            border: 1px solid #c30;
            z-index: 10;
        }
    }
}

:local(.content) {
    display: grid;
    grid-template-columns: 1fr;

    &:local(.withSelectedTask) {
        grid-template-columns: 1fr 500px;
    }
}

:local(.taskDetail) {
    border-left: 1px solid var(--gray6);
    background: #fff;

    :local(.title) {
        font-weight: bold;
        padding: 5px;


    }
}

@media (max-width: 640px) {
    :local(.content) {
        display: block;
        grid-template-columns: 1fr;

        &:local(.withSelectedTask) {
            grid-template-columns: 1fr;
        }
    }

    :local(.taskDetail) {
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        display: grid;
        grid-template-rows: 36px 1fr;
        height: 100%;

        :local(.taskcontent) {
            overflow-y: auto;
        }

        :local(.title) {
            border-bottom: 1px solid var(--gray6);
            padding-bottom: 0;
        }
    }
}