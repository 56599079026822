:local(.task) {
    padding: var(--spacing) var(--spacing_half);
    border-bottom: 1px solid var(--gray6);

    &:last-child {
        border: 0;
    }

    display:grid;
    grid-template-areas: "info";

    :local(.info) {
        grid-area: info;
        cursor: pointer;
        font-size: var(--normal);
        margin-bottom: var(--spacing);
        font-size: var(--small);

        :local(.steps) {
            font-size: var(--normal);

            div {
                display: inline-block;
                margin-right: 2px;
            }

            :local(.step) {
                display: inline-block;
                margin-right: 2px;

                &:after {
                    content: " »";
                }

                &:last-child:after {
                    content: ""
                }
            }
        }
    }

    :local(.devicesSuspectStolen) {
        color: #c30;
        margin-left: var(--spacing_quarter);
        font-weight: bold;
    }

    :local(.comments) {
        margin-top: var(--spacing_half);
        font-style: italic;
    }
}