:local(.filters) {
    background: #fff;
    padding: var(--spacing) 0;
    margin-bottom: var(--spacing);
    display: grid;
    gap: var(--spacing_half);
    grid-template-columns: 1fr auto auto;

    @media (max-width:640px) {
        & {
            grid-template-columns: 1fr;
        }
    }
}

:local(.block) {
    margin-top: var(--spacing2x);
    margin-bottom: var(--spacing2x);

    :local(.title) {
        margin-bottom: var(--spacing);
        padding: 0;
        font-size: var(--normal);
        font-weight: 500;
    }

    :local(.toolsSec) {
        float: right;
    }
}

:local(.metrics) {
    display: grid;
    gap: var(--spacing);
    grid-template-columns: repeat(5, 1fr);

    @media (max-width:640px) {
        & {
            grid-template-columns: 1fr;
        }
    }
}