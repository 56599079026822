:local(.info) {
    grid-area: info;

    table {
        width: 100%;
        border-collapse: collapse;

        td {
            padding: var(--spacing_half);
            border-bottom: 1px solid var(--gray6);
        }
    }
}

:local(.main) {
    display: grid;
    gap: var(--spacing);
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "info map"
        "payments payments";

    @media(max-width:640px) {
        & {
            grid-template-columns: 1fr;
            grid-template-areas: "info"
                "map"
                "payments";
        }
    }

    :local(.info) {
        grid-area: info;
    }

    :local(.map) {
        grid-area: map;
    }

    :local(.payments) {
        grid-area: payments;
    }
}


:local(.tableSmall) {
    width: 100%;
    font-size: var(--small);
}

:local(.cardInfo) {
    font-size: var(--small);
}

:local(.events),
:local(.log) {
    max-height: 500px;
    overflow-y: auto;
}

:local(tr.ok) {
    td {
        background: #efe;
    }
}

:local(tr.nok) {
    td {
        background: #fee;
    }
}