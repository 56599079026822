:local(.icons) {
    grid-area: title;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    text-align: center;
    gap: var(--spacing);
    font-size: var(--small);

    @media(max-width:640px) {
        & {
            text-align: left;
            grid-template-columns: 1fr auto auto auto auto auto;
        }
    }

}


:local(.info) {
    :local(.table) {
        width: 100%;
        border-collapse: collapse;
        font-size: var(--small);

        td {
            padding: var(--spacing_quarter);
            border-bottom: 1px solid var(--gray6);
        }

        tr:last-child td {
            border-bottom: 0;
        }
    }
}

:local(.battery .info) {
    color: #09D76E;
}

:local(.battery.low) {
    color: rgb(168, 14, 99);
}

:local(.lockStatus),
:local(.connectionStatus) {
    text-align: center;
}

:local(.lockStatus .locked) {
    color: #c30;
}

:local(.lockStatus .unlocked) {
    color: #09D76E;
}

:local(.engineStatus .engineOff) {
    color: #ccc;
}

:local(.engineStatus .engineOn) {
    color: #09D76E;
}

:local(.closeBtn) {
    cursor: pointer;
}

:local(.actions) {
    display: grid;
    margin-top: var(--spacing);
    gap: var(--spacing);
    font-size: var(--small);

    :local(.commands) {
        display: grid;
        gap: var(--spacing_quarter);
        grid-template-columns: repeat(8, 1fr);

        @media(max-width:640px) {
            & {
                grid-template-columns: repeat(2, 1fr);
            }
        }

    }

    svg {
        margin-right: var(--spacing_quarter);
    }
}