:local(.table) {
    width: 100%;
    border-collapse: collapse;

    @media (max-width:640px) {

        font-size: var(--small2x);
    }

    th {
        text-align: center;
        padding: var(--spacing_quarter);
    }

    .left {
        text-align: left;
    }

    td {
        text-align: center;
        padding: var(--spacing_quarter);
        border-bottom: 1px solid var(--gray6);
    }
}