:local(.subscription) {
    padding: var(--spacing) 0;
    border-bottom: 1px solid #ddd;
    display: grid;
    grid-template-columns: 1fr auto;

    &:last-child {
        border-bottom: 0
    }

    :local(.itemsArea) {
        grid-column: 1/span 2;
        border-top: 1px dotted #eee;
        margin-top: var(--spacing_half);
        padding-top: var(--spacing_half);
    }
}