:local(.container) {
  position: relative;
  height: 100vh;
  --sidebar-width: 280px;
  --header-height: 92px;
  --header-height-nofleet: 48px;
  --footer-height: 40px;
}

:local(.onlineStatus) {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  text-align: center;

  &:local(.offline) {
  }

  &:local(.online) {
  }
}

:local(.sidebar) {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */

  position: absolute;
  left: 0;
  bottom: var(--footer-height);
  top: 0;
  width: var(--sidebar-width);
  background-color: #fff;
  box-shadow: 2px 0px 8px rgba(0, 0, 0, 0.2);

  z-index: 3;

  :local(.inner) {
    height: 100%;
    display: grid;
    grid-template-rows: auto auto auto 1fr;
  }

  :local(.logo) {
    height: 100px;
    padding: var(--spacing);

    img {
      height: 100%;
    }
  }

  @media (max-width: 640px) {
    z-index: 1000;
  }
}

:local(.position) {
  margin: 10px 0;
}

:local(.positionMobile) {
  position: absolute;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  top: 48px;
  background-color: #fff;
  z-index: 1001;
  left: 0;
  right: 0;

  :local(.coords) {
    font-size: 12px;
    padding: var(--spacing_quarter);
  }
}

:local(.credential) {
  position: absolute;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  top: 48px;
  bottom: 0;
  overflow-y: auto;
  background-color: #fff;
  z-index: 1001;
  left: 0;
  right: 0;
  background: #faf8f4;
  display: grid;
  grid-template-rows: auto 1fr;

  :local(.brand) {
    padding: var(--spacing_half);
    background: var(--mainColor);
    display: grid;
    grid-template-columns: 1fr auto;

    :local(.title) {
      color: #fff;
      font-weight: bold;
      font-size: var(--big4x);
    }

    img {
      height: 40px;
    }
  }

  :local(.card) {
    padding: var(--spacing2x);
    text-align: center;

    :local(.name) {
      font-size: var(--big2x);
      font-weight: bold;
      margin-bottom: var(--spacing);
    }

    :local(.email) {
      font-size: var(--normal);
      font-weight: normal;
      margin-bottom: var(--spacing2x);
    }

    :local(.photo) {
      height: 150px;
      width: 150px;
      margin: 0 auto var(--spacing);
      background-position: center center;
      background-size: cover;
      border-radius: var(--spacing_quarter);
    }
  }
}

:local(.user) {
  padding: var(--spacing);
  border: 1px var(--gray6);
  font-size: var(--small);
  border-style: solid none;

  :local(.logout) {
    margin-top: var(--spacing_half);
  }
}

:local(.fleets) {
  padding: var(--spacing_half);
  border-bottom: 1px solid var(--gray6);
  font-size: var(--small);

  @media (max-width: 640px) {
    padding: 0;
    border-bottom: 0;
  }
}

:local(.work) {
  padding: var(--spacing_half);
  border-bottom: 1px solid var(--gray6);
  font-size: var(--small);
}

:local(.menu) {
  overflow-y: auto;

  :local(.options .option) {
    padding: var(--spacing_half) var(--spacing);
    xborder-bottom: 1px solid var(--gray6);
    font-size: var(--normalsmall);

    &.level2 {
      padding-left: var(--spacing2x);
    }

    svg {
      display: none;
    }

    a {
      color: var(--gray3);
      text-decoration: none;
    }

    &.selected {
      font-weight: bold;
      background: #f5f5f5;

      a {
        color: var(--gray2);
      }
    }
  }

  :local(.burger) {
    display: none;
  }

  @media (max-width: 640px) {
    overflow-y: hidden;

    :local(.menubar) {
      overflow: hidde;
      display: grid;
      grid-template-columns: auto 1fr auto auto auto;
      align-items: center;
      gap: var(--spacing_half);

      :local(.userInfo) {
        padding: var(--spacing_quarter);
      }

      :local(.version) {
        text-align: right;
        font-size: var(--small);
        padding: var(--spacing_quarter);
      }
    }

    :local(.burger) {
      display: block;
      padding: 12px 20px 11px 20px;
      background: #fff;
    }

    :local(.options) {
      position: fixed;
      z-index: 10000;
      background: #fff;
      top: 48px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      overflow-y: auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      gap: 1px;
      padding: 1px 0px;
      background: #eee;

      :local(.option) {
        display: grid;
        align-content: center;
        text-align: center;
        background: #fff;
        font-size: var(--small);
      }

      svg {
        display: none;
      }
    }
  }
}

:local(.topbar) {
  z-index: 2;
  position: absolute;
  left: var(--sidebar-width);
  right: 0;
  background: yellow;
  top: 0;
  height: 50px;
  display: none;
  overflow: hidden;
}

:local(.main) {
  z-index: 2;
  position: absolute;
  left: var(--sidebar-width);
  right: 0;
  bottom: var(--footer-height);
  overflow: auto;

  > :local(.innerFixed) {
    height: 100%;
  }

  @media (min-width: 640px) {
    top: 0px;
  }

  @media (max-width: 640px) {
    top: 0px;
  }
}

:local(.footer) {
  border-top: 1px solid var(--gray7);
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 5;
  right: 0;

  height: var(--footer-height);
  padding: var(--spacing_half);
  background-color: #fff;

  :local(.version) {
    font-size: var(--small);
  }

  @media (max-width: 640px) {
    display: none;
    padding: 0 var(--spacing_half);

    :local(.inner) {
      display: grid;
      align-items: center;
      gap: var(--spacing_half);
      grid-template-columns: auto 1fr;
    }
  }
}

@media (max-width: 640px) {
  :local(.sidebar) {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: auto;
    width: 100%;
    height: var(--header-height-nofleet);

    :local(.logo) {
      display: none;
    }

    :local(.user) {
      display: none;
    }

    :local(.fleets) {
      padding: var(--spacing_quarter);
    }
  }

  :local(.main) {
    position: absolute;
    left: 0;
    right: 0;
    top: var(--header-height-nofleet);

    bottom: var(--footer-height);
    width: 100%;
    height: auto;

    @media (max-width: 640px) {
      bottom: 0;
    }
  }
}

:local(.preferences) {
  display: none;
}

:local(.overlay) {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  display: none;
  background: #000;
  opacity: 0.7;
  z-index: 19999;
}

:local(.scooterDetails) {
  position: absolute;
  border-radius: var(--spacing_half) var(--spacing_half) 0 0;
  background: #fff;
  overflow-y: auto;
  z-index: 20000;

  @media (min-width: 640px) {
    left: 50%;
    margin-left: -400px;
    width: 800px;
    top: 50%;
  }

  @media (max-width: 640px) {
    left: var(--spacing_half);
    right: var(--spacing_half);
    top: var(--spacing_half);
    bottom: 0;
  }
}

:local(.quickOptions) {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;

  :local(.quickOption) {
    &:local(.selected) {
      font-weight: bold;
      background: #f5f5f5;

      a {
        color: var(--gray2);
      }
    }

    background: #fff;
    padding: 5px;
    text-align: center;
    border-radius: 5px;

    a {
      color: #444;
      text-decoration: none;
    }
  }
}
