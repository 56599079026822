:local(.main) {
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: 400px 1fr;
    grid-template-rows: 1fr;

    @media (max-width:640px) {
        height: calc(100vh - 90px);
        grid-template-columns: 1fr;
        grid-template-rows: calc(100vh - 490px) 300px;
    }
}

:local(.list) {
    display: grid;
    grid-template-rows: auto 1fr;
    margin-bottom: 80px;
}

:local(.actions) {
    display: grid;
    gap: var(--spacing_half);
}

:local(.content) {
    overflow: hidden;
    overflow-y: auto;
}

:local(.actions) {
    padding: var(--spacing_half);
}