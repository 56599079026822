:local(.cnt){
    padding:var(--spacing);
    border-bottom: 1px solid #eee;
}
:local(.info){
    cursor: pointer;
    display: grid;
    grid-template-columns: 1fr auto;
}
:local(.title.status_inactive){
    color:#ccc;
}

:local(.field){
    margin-top:var(--spacing_half);
}
:local(.buttons){
    margin-top:var(--spacing);
}