:local(.alert){
    padding:var(--spacing) var(--spacing_half);
    border-bottom:1px solid var(--gray6);
    &:last-child{
        border:0;
    }
    display:grid;
    grid-template-areas: "info actions"
    "device device";
    :local(.info){
        grid-area:info;
        cursor:pointer;
        font-size:var(--normal);
        margin-bottom:var(--spacing);
        :local(.date),
        :local(.fleet){
            font-size:var(--small);
        }
    }
    :local(.actions){
        grid-area:actions;
        text-align: right;
    }
    :local(.device){
        grid-area:device;
    }
}
