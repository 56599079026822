:local(.events) {
    font-size: 12px;
    border: 1px solid var(--gray6);
    padding: var(--spacing_half);
    display: grid;
    height: 100%;
    overflow: hidden;
    grid-template-rows: auto 1fr;
    gap: var(--spacing_half);

    :local(.filters) {}

    :local(.list) {
        overflow-y: scroll;

        :local(.item) {
            padding: var(--spacing_quarter);
            gap: var(--spacing_half);
            border-bottom: 1px solid var(--gray6);
            display: grid;
            grid-template-columns: 220px 1fr;

            @media(max-width:640px) {
                grid-template-columns: 1fr;

            }

            :local(.date) {}

            :local(.event) {
                font-weight: var(--strong);
            }

            :local(.data) {}
        }
    }
}