:local(.byStatus) {
    display: grid;
    gap: var(--spacing_half);
}


:local(.block) {
    margin-bottom: var(--spacing2x);

    :local(.title) {
        margin-bottom: var(--spacing);
        padding: 0;
        font-size: var(--normal);
        font-weight: 500;
    }
}

:local(.rowIncomplete) {
    background: #fee;
}

:local(.rowComplete) {
    background: #cfc;
}