:local(.page) {
  padding: var(--spacing);
  margin-bottom: 50px;

  :local(.secAction) {
    float: right;
  }

  :local(.content) {
    position: relative;
  }

  :local(.small) {
    font-size: var(--small);
  }

  :local(.breadcrumb) {
    margin-top: var(--spacing);
    font-size: var(--small);
    color: var(--gray2);

    :local(.el::after) {
      content: " » ";
    }

    :local(.el:last-child::after) {
      content: "";
    }
  }

  :local(.secRight) {
    float: right;
  }

  :local(.title) {
    margin: 0 0 var(--spacing) 0px;
    font-size: var(--big4x);
    color: var(--gray2);
  }

  :local(.subtitle) {
    margin: var(--spacing) 0px;
    font-size: var(--small);
    color: var(--gray2);
  }

  :local(.title + .subtitle) {
    margin-top: -20px;
  }

  :local(.tools) {
    margin-bottom: var(--spacing);
  }

  :local(.boxedSection) {
    border: 1px solid #eee;
    padding: var(--spacing_half);
    margin: var(--spacing_half) 0;
  }

  :local(.section) {
    padding: var(--spacing_half) 0;
    margin: var(--spacing_half) 0;
  }

  :local(.sectionHeader) {
    margin: var(--spacing) 0px;
    padding: 0;
    font-size: var(--big);
    color: var(--gray2);
  }

  :local(.boxedSection) {
    :local(.sectionHeader) {
      margin-top: 0;
    }
  }

  :local(.sectionTitle) {
    margin: 0px 0px var(--spacing);
    padding: 0;
    font-size: var(--normal);
    color: var(--gray2);
  }

  :local(.loading) {
    padding: 100px 0;
    text-align: center;
  }

  :local(.message) {
    padding: 50px 20px;
    text-align: center;
  }
}

:local(.table) {
  border-collapse: collapse;
  width: 100%;

  th {
    text-align: left;
    border-bottom: 2px solid #ccc;
    padding: var(--spacing_half);
  }

  td {
    padding: var(--spacing_half);
    border-bottom: 1px solid #eee;

    &:local(.right) {
      text-align: right;
    }
  }
}

:local(.tableSmall) {
  th {
    font-size: var(--small);
    padding: var(--spacing_quarter);
  }

  td {
    padding: var(--spacing_quarter);
    font-size: var(--small);
  }
}

:global(button):local(.roundedButton) {
  border-radius: 50px;

  &:hover,
  &:focus {
    border-radius: 50px !important;
  }
}

@media (max-width: 640px) {
  :local(.page) {
    padding: var(--spacing_quarter);
  }
}

:local(.filters) {
  background: #fff;
  padding: var(--spacing) 0;
  margin-bottom: var(--spacing);
  display: grid;
  gap: var(--spacing_half);
  grid-template-columns: 1fr auto auto;

  @media (max-width: 640px) {
    & {
      grid-template-columns: 1fr;
    }
  }
}

:local(.block) {
  margin-bottom: var(--spacing2x);

  :local(.title) {
    margin-bottom: var(--spacing);
    padding: 0;
    font-size: var(--normal);
    font-weight: 500;
  }

  :local(.toolsSec) {
    float: right;
  }
}

:local(.2cols) {
  display: grid;
  gap: var(--spacing_half);
  grid-template-columns: 1fr 1fr;
}

:local(.2colsauto) {
  display: grid;
  gap: var(--spacing_half);
  grid-template-columns: 1fr auto;
}

:local(.inputWithButton) {
  display: grid;
  gap: var(--spacing_half);
  grid-template-columns: 1fr auto;
}

:local(.link) {
  color: var(--mainColor);
  text-decoration: underline;
  cursor: pointer;
}

:local(.rowPositive) {
  background: #efe;
}

:local(.rowWeekend) {
  background: #ffeecc;
}
:local(.rowNegative) {
  background: #fee;
}

:local(.badgePositive) {
  background: #090;
  color: #fff;
  border-radius: 50px;
  padding: 3px 5px;
  font-size: var(--small);
}

:local(.badgeNegative) {
  background: #c30;
  color: #fff;
  border-radius: 50px;
  padding: 3px 5px;
  font-size: var(--small);
}

:local(.uploader) {
  cursor: pointer;
  background: var(--gray7);
  border: 1px solid var(--gray6);
  border-radius: 5px;
  overflow: hidden;
  display: block;
  padding: 0;
  width: 100%;

  :local(.uploaderInner) {
    text-align: center;
    padding: var(--spacing_half);
  }
}

table:local(.infotable) {
  width: 100%;
  border-collapse: collapse;

  td {
    padding: var(--spacing_half);
    border-bottom: 1px solid var(--gray6);
  }
}
