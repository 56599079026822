:local(.user) {
    padding: var(--spacing2x);
    text-align: center;

    :local(.name) {
        font-size: var(--big2x);
        font-weight: bold;
        margin-bottom: var(--spacing);
    }

    :local(.email) {
        font-size: var(--normal);
        font-weight: normal;
        margin-bottom: var(--spacing2x);
    }

    :local(.photo) {
        height: 200px;
        width: 200px;
        margin: var(--spacing) auto var(--spacing2x);
        background-position: center center;
        background-size: cover;
        border-radius: var(--spacing_quarter);
    }

}