:local(.hoursTimeline) {
    margin: var(--spacing2x) 0;

    :local(.line) {
        display: grid;

    }

    :local(.titleLine) {
        font-size: var(--small);
        width: 90px;
        text-align: left;
        padding: var(--spacing_half);
        padding-right: var(--spacing);
        font-weight: bold;
        margin-right: -1px;

        @media(max-width:640px) {
            & {
                padding: 0;
            }
        }
    }

    :local(.hour) {
        font-size: var(--small);
        text-align: center;
        margin-right: -1px;

    }

    :local(.item) {
        background: #f5f5f5;
        border: 1px solid #eee;
        padding: var(--spacing_half);
        font-size: var(--small);
        text-align: center;
        margin-right: -1px;

        @media(max-width:640px) {
            & {
                padding: 0;
            }
        }

        &:local(.hasValue) {
            background: #ffeeee;
            color: #c30;
            border: 1px solid #c30;
            z-index: 10;
        }
    }
}